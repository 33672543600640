@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

//Background Colors

$blues: ($blue-100, $blue-200, $blue-300, $blue-400, $blue-500, $blue-600, $blue-700, $blue-800, $blue-900);

$indigos: (
  $indigo-100,
  $indigo-200,
  $indigo-300,
  $indigo-400,
  $indigo-500,
  $indigo-600,
  $indigo-700,
  $indigo-800,
  $indigo-900
);

$purples: (
  $purple-100,
  $purple-200,
  $purple-300,
  $purple-400,
  $purple-500,
  $purple-600,
  $purple-700,
  $purple-800,
  $purple-900
);

$pinks: ($pink-100, $pink-200, $pink-300, $pink-400, $pink-500, $pink-600, $pink-700, $pink-800, $pink-900);

$reds: ($red-100, $red-200, $red-300, $red-400, $red-500, $red-600, $red-700, $red-800, $red-900);

$oranges: (
  $orange-100,
  $orange-200,
  $orange-300,
  $orange-400,
  $orange-500,
  $orange-600,
  $orange-700,
  $orange-800,
  $orange-900
);

$yellows: (
  $yellow-100,
  $yellow-200,
  $yellow-300,
  $yellow-400,
  $yellow-500,
  $yellow-600,
  $yellow-700,
  $yellow-800,
  $yellow-900
);

$greens: ($green-100, $green-200, $green-300, $green-400, $green-500, $green-600, $green-700, $green-800, $green-900);

$teals: ($teal-100, $teal-200, $teal-300, $teal-400, $teal-500, $teal-600, $teal-700, $teal-800, $teal-900);

$cyans: ($cyan-100, $cyan-200, $cyan-300, $cyan-400, $cyan-500, $cyan-600, $cyan-700, $cyan-800, $cyan-900);

$mycolors: (
  "blue": $blues,
  "indigo": $indigos,
  "purple": $purples,
  "pink": $pinks,
  "red": $reds,
  "orange": $oranges,
  "yellow": $yellows,
  "green": $greens,
  "teal": $teals,
  "cyan": $cyans,
);

@function index-to-key($index) {
  $keys: map-keys($grays); // A list of all keys in a $breakpoints map
  @return nth($keys, $index);
}

@for $i from 1 through length($grays) {
  $colorValue: map-get($grays, index-to-key($i));
  .bg-gray-#{$i} {
    background-color: $colorValue !important;
  }
}

@each $colorBaseName, $colorArray in $mycolors {
  @each $color in $colorArray {
    $x: index($colorArray, $color);
    .bg-#{$colorBaseName}-#{$x} {
      background-color: $color !important;
    }
  }
}

//Buttons
@mixin bootstrap-button($background, $fontcolordark: false) {
  $color: #fff;
  @if ($fontcolordark) {
    $color: #333;
  }
  @include button-variant($background, $background, $color);
}

.btn-sec-green {
  @include bootstrap-button($green-500);
}

.btn-sec-yellow {
  @include bootstrap-button($yellow-400);
}

.btn-sec-cyan {
  @include bootstrap-button($cyan-400);
}

.btn-sec-gray {
  @include bootstrap-button($gray-500);
}

.btn-sec-orange {
  @include bootstrap-button($orange-500);
}

.btn-light-indigo {
  @include bootstrap-button($indigo-200);
}
